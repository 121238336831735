import React, {useEffect} from 'react';
import {Router, navigate} from '@reach/router';
import {generateRoute, ROUTES} from "../../common";

function Redirect(props) {
    useEffect(() => {
        if (props.query_param_ticker_symbol) {
            navigate(generateRoute(ROUTES.STOCKS, { stock_code: props.query_param_ticker_symbol }));
            return;
        }

        navigate(ROUTES.STOCKS);
    }, []);

    return null;
}

// This page is temporarily added so we could redirect routes from /stock/{ticker_symbol} to /stocks/{ticker_symbol}
// Ideally this should not have be necessary but I can't make the createRedirect gatsby action on build work. Hopefully
// on the next release I could make the HTTP 301 redirect to work.
function Stock() {
    return (
        <Router basepath="/stock">
            <Redirect path="/:query_param_ticker_symbol"/>
            <Redirect path="/"/>
        </Router>
    )
}

export default Stock;